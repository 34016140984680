import React, {useState, useEffect, useRef} from "react"
// import { useNavigate } from 'react-router-dom';
// import Header from "../../../Components/Header/Header"
import Select from 'react-select';
// import NumberInput from "../../../Inputs/NumberInput"
import leftArrow from "./Assests/Images/left_arrow.svg"
import rightArrow from "./Assests/Images/right_arrow.svg"
import edit from "./Assests/Images/edit.svg"
import { getDashCalendar, getDashCalendarByListing, updatePricing, blockDay, updatePrice } from "../../services/api";
// import FullPageLoader from "../../../Utils/FullPageLoader"
// import { useAuth } from "../../../Utils/Auth"
import CloseIcon from '@mui/icons-material/Close';
import { addWeeks, subWeeks, startOfWeek, endOfWeek, format, eachDayOfInterval, addMonths, subMonths, getISOWeek, startOfMonth } from 'date-fns';
import { PageLoader } from "../../services/PageLoader";
import './Calendar.css'

const Calendar = ({userId, locationId}) => {
    // const navigate = useNavigate();
    const divRef = useRef();
    const [activeTab,setActiveTab] = useState('Month')
    const [enquiries,setEnquiries] = useState([])
    const [listing, setListing] = useState(locationId || null);
    const [listings,setListings] = useState([])
    const [eventTypes,setEventTypes] = useState([]);
    const [eventType,setEventType] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loader, setLoader] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [formData,setFormData] = useState({})
    const [data,setData] = useState({})
    const [enabledDates,setEnabledDates] = useState([])
    const [disabledDates,setDisabledDates] = useState([])
    const [customPricing,setCustomPricings] = useState(null)
    const [price,setPrice] = useState('')
    const [enable,setEnable] = useState(false)
    const [active,setActive] = useState(false)
    const [block,setBlock] = useState(false)
    const [errors, setErrors] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedLeadDate, setSelectedLeadDate] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const handleTab = (tab) => {
        setActiveTab(tab)
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }else{
            setErrors({...errors,[name] : `This field is required`})
        }
    };
    const onChangeOption = (obj) => {
        setListing(obj?.value)
        setSelectedDate(null)
        setSelectedLeadDate(null)
        setCurrentDate(new Date())

        // for disabling edit
        if (showEdit) {
            setShowEdit(false)
            setFormData({})
            setErrors({})
        }
    };
    const onChangeOptionEvent = (obj) => {
        setEventType(obj?.value)
    };
    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };
    const onEditDay = (day,date,active,blocked,price) => {
        setSelectedLeadDate(date)
        setSelectedDate(null)
        if(date.toString() === selectedLeadDate?.toString()){
            console.log('dfsgdfzg')
            setSelectedLeadDate(null)
            setSelectedDate(null)
            return null
        }
        // if(!showEdit) return null
        let today = new Date(new Date().setHours(0,0,0,0))
        if(date < today) return null
        handleEdit()
        setSelectedDate(date)
        setPrice(price)
        setActive(active)
        setBlock(blocked)
        const enabled = enabledDates?.includes(date.toISOString().toString())
        setEnable(enabled)
        setErrors({});
    }
    function isWeekend(date) {
        const day = date.getDay();
        return day === 5 || day === 6 || day === 0;
    }
    const getCustomPrice = (date) => {
        if(!customPricing?.[eventType]) return null
        for(let each of customPricing?.[eventType]){
            if(each?.date === date.toISOString().toString()){
                return each?.price
            }
        }
        return null
    }
    // const callNavigate = (type, locationId) => {
    //     navigate(`/apps/lead/${type}`, { state : {locationId} });
    // }
    const getDayEnquiries =(day,date,enquiries,active,blocked,showPrice) => {
        let dayEnquiries = []
        let GIT = 0
        let SPV = 0
        let CH = 0
        let BR = 0
        enquiries?.map((each)=>{
            if(date.toString() === new Date(new Date(each?.createdAt).setHours(0,0,0,0)).toString()){
                dayEnquiries.push(each)
                switch (each?.type) {
                    case 'Schedule Property Visit':
                        SPV++
                        break;
                    case 'Booking Request':
                        BR++
                        break;
                    case 'Contact Host':
                        CH++
                        break;
                    case 'Get In Touch':
                        GIT++
                        break;
                
                    default:
                        break;
                }
            }
        })
        return(
                <div className={`sp_calendar_day ${selectedLeadDate?.toString() === date?.toString() ? "sp_calendar_day_lead" : ""}`} id={`sp_calendar_day${day}`} ref={divRef}>
                    {(eventType === 'stay' && showEdit && !blocked && (date >= new Date().setHours(0,0,0,0))) && (
                        <div className="d-none d-md-flex flex-row justify-content-center align-items-center">
                            <div className="sp_calendar_price_text_container mr-2">
                                <span className="content" id='day_price_text'>₹ {showPrice}</span>
                            </div>
                        </div>
                    )}
                    {(eventType === 'stay' && showEdit && selectedLeadDate?.toString() === date?.toString() && !blocked && (date >= new Date().setHours(0,0,0,0))) && (
                        <div className="d-flex d-md-none flex-row justify-content-center align-items-center">
                            <div className="sp_calendar_price_text_container mr-2">
                                <span className="content" id='day_price_text'>₹ {showPrice}</span>
                            </div>
                        </div>
                    )}
                    {dayEnquiries?.length > 0 && (
                        <div className="sp_calendar_day_enquiry_section">
                            {CH > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active' : 'sp_calendar_day_enquiry_section_item'} CH`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active_sub' : 'sp_calendar_day_enquiry_section_item_sub'} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Contact Host',listing : listing,startDate : date,endDate : date}})}
                                // onClick = { () => callNavigate("contact-host", listing) }
                                >
                                    <span className="content text_color_1">Contact Host</span>
                                    <span className="content text_color_1">{CH}</span>
                                </div>
                            </div>
                            }
                            {SPV > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active' : 'sp_calendar_day_enquiry_section_item'} SPV`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active_sub' : 'sp_calendar_day_enquiry_section_item_sub'} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Schedule Property Visit',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Schedule a visit</span>
                                    <span className="content text_color_1">{SPV}</span>
                                </div>
                            </div>
                            }
                            {BR > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active' : 'sp_calendar_day_enquiry_section_item'} BR`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active_sub' : 'sp_calendar_day_enquiry_section_item_sub'} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Booking Request',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Booking request</span>
                                    <span className="content text_color_1">{BR}</span>
                                </div>
                            </div>
                            }
                            {GIT > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active' : 'sp_calendar_day_enquiry_section_item'} GIT`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_active_sub' : 'sp_calendar_day_enquiry_section_item_sub'}  
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Get In Touch',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Get in touch</span>
                                    <span className="content text_color_1">{GIT}</span>
                                </div>
                            </div>
                            }
                        </div>
                    )}
                    {blocked && (
                        <div className="sp_day_box_blocked">
                            <span className="content text_color_3">Blocked</span>
                        </div>
                    )}
                </div>
        )
    }
    const getDayEnquiriesWeek =(day,date,enquiries,active,blocked,showPrice) => {
        let dayEnquiries = []
        let GIT = 0
        let SPV = 0
        let CH = 0
        let BR = 0
        enquiries?.map((each)=>{
            if(date.toString() === new Date(new Date(each?.createdAt).setHours(0,0,0,0)).toString()){
                dayEnquiries.push(each)
                switch (each?.type) {
                    case 'Schedule Property Visit':
                        SPV++
                        break;
                    case 'Booking Request':
                        BR++
                        break;
                    case 'Contact Host':
                        CH++
                        break;
                    case 'Get In Touch':
                        GIT++
                        break;
                
                    default:
                        break;
                }
            }
        })
        return(
                <div className={`sp_calendar_day}`} id={`sp_calendar_day${day}`} ref={divRef}>
                    {(eventType === 'stay' && showEdit && !blocked && (date >= new Date().setHours(0,0,0,0))) && (
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <div className="sp_calendar_price_text_container mr-2">
                                <span className="content" id='day_price_text'>₹ {showPrice}</span>
                            </div>
                        </div>
                    )}
                    {dayEnquiries?.length > 0 && (
                        <div className="sp_calendar_day_enquiry_section">
                            {CH > 0 && 
                            <div className={`sp_calendar_day_enquiry_section_item_active CH`}>
                                <div className={`sp_calendar_day_enquiry_section_item_active_sub`} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Contact Host',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Contact Host</span>
                                    <span className="content text_color_1">{CH}</span>
                                </div>
                            </div>
                            }
                            {SPV > 0 && 
                            <div className={`sp_calendar_day_enquiry_section_item_active SPV`}>
                                <div className={`sp_calendar_day_enquiry_section_item_active_sub`} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Schedule Property Visit',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Schedule a visit</span>
                                    <span className="content text_color_1">{SPV}</span>
                                </div>
                            </div>
                            }
                            {BR > 0 && 
                            <div className={`sp_calendar_day_enquiry_section_item_active BR`}>
                                <div className={`sp_calendar_day_enquiry_section_item_active_sub`} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Booking Request',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Booking request</span>
                                    <span className="content text_color_1">{BR}</span>
                                </div>
                            </div>
                            }
                            {GIT > 0 && 
                            <div className={`sp_calendar_day_enquiry_section_item_active GIT`}>
                                <div className={`sp_calendar_day_enquiry_section_item_active_sub`} 
                                //  onClick={()=> navigate('/home',{state : {enquiry : 'Get In Touch',listing : listing,startDate : date,endDate : date}})}
                                 >
                                    <span className="content text_color_1">Get in touch</span>
                                    <span className="content text_color_1">{GIT}</span>
                                </div>
                            </div>
                            }
                        </div>
                    )}
                    {blocked && (
                        <div className="sp_day_box_blocked">
                            <span className="content text_color_3">Blocked</span>
                        </div>
                    )}
                </div>
        )
    }
    const getDayEnquiriesYear =(day,date,enquiries,active,blocked,showPrice) => {
        let dayEnquiries = []
        let GIT = 0
        let SPV = 0
        let CH = 0
        let BR = 0
        enquiries?.map((each)=>{
            if(date.toString() === new Date(new Date(each?.createdAt).setHours(0,0,0,0)).toString()){
                dayEnquiries.push(each)
                switch (each?.type) {
                    case 'Schedule Property Visit':
                        SPV++
                        break;
                    case 'Booking Request':
                        BR++
                        break;
                    case 'Contact Host':
                        CH++
                        break;
                    case 'Get In Touch':
                        GIT++
                        break;
                
                    default:
                        break;
                }
            }
        })
        return(
                <div className={`sp_calendar_day ${selectedLeadDate?.toString() === date?.toString() ? "sp_calendar_day_lead_year" : ""}`} id={`sp_calendar_day${day}`} ref={divRef}>
                    {/* {(eventType === 'stay' && showEdit) && (
                        <div className="d-none d-md-flex flex-row justify-content-center align-items-center">
                            <div className="sp_calendar_price_text_container mr-2">
                                <span className="mini_content text_color_1" id='day_price_text'>₹ {showPrice}</span>
                            </div>
                        </div>
                    )} */}
                    {(eventType === 'stay' && showEdit && selectedLeadDate?.toString() === date?.toString() && !blocked && (date >= new Date().setHours(0,0,0,0))) && (
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <div className="sp_calendar_price_text_container mr-2">
                                <span className="content" id='day_price_text'>₹ {showPrice}</span>
                            </div>
                        </div>
                    )}
                    {dayEnquiries?.length > 0 && (
                        <div className="sp_calendar_day_enquiry_section">
                            {CH > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_year_active' : 'sp_calendar_day_enquiry_section_item_year'} CH`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'd-flex flex-row justify-content-between w-100' : 'd-none'} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Contact Host',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Contact Host</span>
                                    <span className="content text_color_1">{CH}</span>
                                </div>
                            </div>
                            }
                            {SPV > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_year_active' : 'sp_calendar_day_enquiry_section_item_year'} SPV`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'd-flex flex-row justify-content-between w-100' : 'd-none'} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Schedule Property Visit',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Schedule a visit</span>
                                    <span className="content text_color_1">{SPV}</span>
                                </div>
                            </div>
                            }
                            {BR > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_year_active' : 'sp_calendar_day_enquiry_section_item_year'} BR`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'd-flex flex-row justify-content-between w-100' : 'd-none'} 
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Booking Request',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Booking request</span>
                                    <span className="content text_color_1">{BR}</span>
                                </div>
                            </div>
                            }
                            {GIT > 0 && 
                            <div className={`${(selectedLeadDate?.toString() === date?.toString()) ? 'sp_calendar_day_enquiry_section_item_year_active' : 'sp_calendar_day_enquiry_section_item_year'} GIT`}>
                                <div className={(selectedLeadDate?.toString() === date?.toString()) ? 'd-flex flex-row justify-content-between w-100' : 'd-none'}  
                                // onClick={()=> navigate('/home',{state : {enquiry : 'Get In Touch',listing : listing,startDate : date,endDate : date}})}
                                >
                                    <span className="content text_color_1">Get in touch</span>
                                    <span className="content text_color_1">{GIT}</span>
                                </div>
                            </div>
                            }
                        </div>
                    )}
                    {blocked && (
                        <span className="mini_content text_color_3">Blocked</span>
                    )}
                </div>
        )
    }
    const handleEdit = () => {
        setShowEdit(true)
        if(!showEdit){
            setFormData({
                weekdayPrice : data?.stay?.weekdayPrice,
                weekendPrice : data?.stay?.weekendPrice,
                extraGuestFee : data?.stay?.extraGuestFee,
                cleaningFee : data?.stay?.cleaningFee,
                securityDeposit : data?.stay?.securityDeposit,
            })
        }
        setErrors({})
        const div = document.getElementById('sp_calendar_wrap_container_2')
        if(div) div.scrollIntoView({ behavior: 'smooth',block: 'nearest' })
    }
    const handleEditClose = () => {
        setShowEdit(false)
        setSelectedDate(null)
        setSelectedLeadDate(null)
        setErrors({})
    }
    const isActive = (inDate) => {
        if(!showEdit) return true
        const date = new Date(inDate);
        const weekday = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
        const days = []
        const timings = data?.[eventType]?.timings
        Object.keys(timings).map((day,i)=>{
            if(timings?.[day] === true || timings?.[day]?.fullDay || timings?.[day]?.timings){
                days.push(day)
            }
        })
        return days.includes(weekday)
    }
    const onBlockChnage = async () => {
        setLoader(true)
        setBlock(!block)
        try{
            const resp = await blockDay({listingId : listing,date : selectedDate,block : true})
            alert(resp?.data?.message)
            getMonthEnquiry(listing,currentDate)
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const onEnableChnage = async () => {
        setLoader(true)
        setEnable(!enable)
        try{
            const resp = await blockDay({listingId : listing,date : selectedDate,block : false})
            alert(resp?.data?.message)
            getMonthEnquiry(listing,currentDate)
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const renderCalendar = () => {
        const daysInMonth = getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());
        const firstDayIndex = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

        const rows = [];
        let cells = [];

        // Blank cells for days before the 1st of the month
        for (let i = 0; i < firstDayIndex; i++) {
        cells.push(<td key={`empty-${i}`} className="sp_empty"></td>);
        }

        // Days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(currentDate);
            date.setDate(day)
            date.setHours(0, 0, 0, 0);
            let active = isActive(date)
            let blocked
            blocked = disabledDates?.includes(date.toISOString().toString())
            let enabled
            enabled = enabledDates?.includes(date.toISOString().toString())
            let showPrice = isWeekend(date) ? data?.stay?.weekendPrice : data?.stay?.weekdayPrice
            if(customPricing){
                const price = getCustomPrice(date)
                if(price){
                    showPrice = price
                }  
            }
            let today = new Date(new Date().setHours(0,0,0,0))
            cells.push(
                <td key={day} className={`sp_day ${((active === false && enabled === false) || blocked === true) ? 'sp_day_disable' : ''} ${selectedDate?.toString() === date?.toString() ? 'sp_day_active' : ''} ${date?.toString() === today?.toString() ? 'sp_calender_today' : ''}`} id={`sp_day_${day}`}>
                    <div className="sp_day_box cursor" onClick={() => onEditDay(day,date,active,blocked,showPrice)}>
                        <span className={`content ${((active === false && enabled === false) || blocked === true) ? 'text_color_3' : 'text_color_1'}`}>{day}</span>
                        {getDayEnquiries(day,date,enquiries,active,blocked,showPrice)}
                    </div>
                {/* You can add events, notes, etc. inside each day */}
                </td>
            );

            // When the week is complete, push the row
            if (cells.length === 7) {
                rows.push(<tr key={`week-${rows.length}`}>{cells}</tr>);
                cells = []; // Clear cells for the next week
            }
        }

        // Add the remaining days to the last row
        if (cells.length > 0) {
        while (cells.length < 7) {
            cells.push(<td key={`empty-${cells.length}`} className="sp_empty"></td>);
        }
        rows.push(<tr key={`week-${rows.length}`}>{cells}</tr>);
        }

        return rows;
    };
    const getMonthEnquiry = async(value,date) => {
        setLoader(true)
        try{
            const resp2 = await getDashCalendarByListing(value,date)
            setEnquiries(resp2?.data?.enquiryData);
            setData(resp2?.data?.data?.pricing)
            setEnabledDates(resp2?.data?.data?.enabledDates)
            setDisabledDates(resp2?.data?.data?.disabledDates)
            setCustomPricings(resp2?.data?.data?.customPricing)
            let initialTypes = []
            if(resp2?.data?.data?.pricing?.film?.available){
                initialTypes.push({label : "Film Shootings", value : "film"})
                setEventType(eventType || 'film')
            }
            if(resp2?.data?.data?.pricing?.tv?.available){
                initialTypes.push({label : "Tv Shootings", value : "tv"})
                setEventType(eventType || 'tv')
            }
            if(resp2?.data?.data?.pricing?.event?.available){
                initialTypes.push({label : "Events", value : "event"})
                setEventType(eventType || 'event')
            }
            if(resp2?.data?.data?.pricing?.stay?.available){
                initialTypes.push({label : "Staycations", value : "stay"})
                setEventType(eventType || 'stay')
            }
            setEventTypes(initialTypes)
            setFormData({
                weekdayPrice : resp2?.data?.data?.pricing?.stay?.weekdayPrice,
                weekendPrice : resp2?.data?.data?.pricing?.stay?.weekendPrice,
                extraGuestFee : resp2?.data?.data?.pricing?.stay?.extraGuestFee,
                cleaningFee : resp2?.data?.data?.pricing?.stay?.cleaningFee,
                securityDeposit : resp2?.data?.data?.pricing?.stay?.securityDeposit,
            })
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const getData = async() => {
        setLoader(true)
        try{
            // const resp = await getDashCalendar({userId});
            setListings(listing ? [{value : listing, label : listing}] : []);
            setListing(listing || "")
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const validateForm = () => {
        const newErrors = {
            weekdayPrice: formData.weekdayPrice ? '' : 'Weekday price is required',
            weekendPrice: formData.weekendPrice ? '' : 'Weekend price is required',
        };
        setErrors(newErrors);
    
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('calendar_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    };
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        if (validateForm()) {
            console.log('Form submitted successfully:', formData);
            try{
                const resp = await updatePricing(listing,{...formData})
                getMonthEnquiry(listing,currentDate)
                alert(resp?.data?.message || 'Details successfully')
            }catch(error){
                alert(error?.responce?.data?.message || 'Error while updating details')
                console.log(error)
            }
        } else {
        console.log('Form validation failed');
        }
        setLoader(false)
    };
    const validatePriceForm = () => {
        const newErrors = {
            price: price ? '' : 'Price is required',
        };
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('calendar_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    }
    const onSubmitCustomPrice = async(e) => {
        e.preventDefault();
        setLoader(true);
        if (validatePriceForm()) {
            // const text = document.getElementById('day_price_text').textContent
            // console.log(text)
            // if(price === text){
            //     notify('Actual price and custom price is same')
            //     return null
            // }
            try{
                const resp = await updatePrice({selectedDate,listing,eventType,price})
                alert(resp?.data?.message || 'Details successfully')
                getMonthEnquiry(listing,currentDate)
            }catch(error){
                alert(error?.responce?.data?.message || 'Error while updating details')
            }
        }else {
            console.log('Form validation failed');   
        }
        setLoader(false)
    }
    const changeYear = (offset) => {
        setYear(prevYear => prevYear + offset);
    };
    useEffect(()=>{
        getData()
    },[])
    useEffect(()=>{
        if(listing) getMonthEnquiry(listing,currentDate)
    },[listing,currentDate])
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            // setSelectedLeadDate(null)
            // setSelectedDate(null)
        }
    };
    useEffect(()=>{
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            };
    },[])
    const [currentDateNew, setCurrentDateNew] = useState(new Date());
    const startOfCurrentWeek = startOfWeek(currentDateNew, { weekStartsOn: 0 });
    const endOfCurrentWeek = endOfWeek(currentDateNew, { weekStartsOn: 0 });
    const daysOfWeek = eachDayOfInterval({ start: startOfCurrentWeek, end: endOfCurrentWeek });
    const getWeekNumber = (date) => {
      const startOfMonthDate = startOfMonth(date);
      return Math.ceil(((date.getDate() - startOfMonthDate.getDate() + 1) / 7));
    };
    const weekNumber = getWeekNumber(currentDateNew);
    const handlePreviousWeek = () => setCurrentDateNew(subWeeks(currentDateNew, 1));
    const handleNextWeek = () => setCurrentDateNew(addWeeks(currentDateNew, 1));
    const handlePreviousMonth = () => setCurrentDateNew(subMonths(currentDateNew, 1));
    const handleNextMonth = () => setCurrentDateNew(addMonths(currentDateNew, 1));

    return (
        <div className='Spotlet'>
            <div className='sp_calendar_main_container' id='sp_calendar_main_container'>
                <span className="main_text heading_color_1 justify-content-start2">Calendar</span>
                <div className="sp_calendar_section_wrap">
                    <div className="sp_calendar_wrap_container mt-2">
                        <div className="sp_calendar_header">
                            <div className="sp_calendar_enquiry_wrap_section cursor">
                                <div onClick={()=>handleTab('Month')} className={activeTab === 'Month' ? 'sp_calendar_enquiry_text_section_active' : 'sp_calendar_enquiry_text_section'}>
                                    <span className="content cursor-pointer">Month</span>
                                </div>
                                <div onClick={()=>handleTab('Year')} className={activeTab === 'Year' ? 'sp_calendar_enquiry_text_section_active' : 'sp_calendar_enquiry_text_section'}>
                                    <span className="content cursor-pointer">Year</span>
                                </div>
                                <div onClick={()=>handleTab('Week')} className={activeTab === 'Week' ? 'sp_calendar_enquiry_text_section_active' : 'sp_calendar_enquiry_text_section'}>
                                    <span className="content cursor-pointer">Week</span>
                                </div>
                            </div>
                            <div className="sp_calendar_header_right_wrapper">
                                <div className="sp_calendar_input_wrapper">
                                    <span className="content text_color_1 mt-1">Select property :</span>
                                    <div className="sp_calendar_header_right_wrapper_input">
                                        <Select
                                            name='enquiry'
                                            options={listings}
                                            value={ listing ? { label : listing, value : listing} : ""}
                                            onChange={onChangeOption}
                                            placeholder='Select listing'
                                            inputStyle={{boxShadow : '0px 4px 44px 0px #0000001A'}}
                                            style={{zIndex : '4'}}
                                            hideClearButton={true}
                                        />
                                    </div>
                                </div>
                                <div className="sp_calendar_header_right_wrapper_edit">
                                    <button className="btn btn-primary w-100" onClick={handleEdit}>Edit <img src={edit} alt="edit"/></button>
                                </div>
                            </div>
                        </div>
                        {activeTab === 'Month' && (
                            <div className="sp_calendar">
                                <div className="sp_calendar-header">
                                    <img onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))} src={leftArrow} alt = 'leftArrow' />
                                    <span className="main_text heading_color_1">{currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}</span>
                                    <img onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))} src={rightArrow} alt = 'rightArrow' />
                                </div>
                                <table className="sp_calendar-table">
                                    <thead>
                                    <tr className="sp_calender_desk_header">
                                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, idx) => (
                                        <th key={idx} className="sub_text text_color_1">{day}</th>
                                        ))}
                                    </tr>
                                    <tr className="sp_calender_mobile_header">
                                        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, idx) => (
                                        <th key={idx} className="sub_text text_color_1">{day}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderCalendar()}
                                    </tbody>
                                </table>
                                </div>
                        )}
                        {activeTab === 'Year' && (
                            <div className="sp_year-calendar-container">
                                <div className="sp_calendar-header">
                                    <img onClick={() => changeYear(-1)} src={leftArrow} alt = 'leftArrow' />
                                    <span className="main_text heading_color_1">{year}</span>
                                    <img onClick={() => changeYear(1)} src={rightArrow} alt = 'rightArrow' />
                                </div>
                        
                                <div className="sp_year-months-grid">
                                {months.map((month, index) => (
                                    <div key={month} className="sp_year-month">
                                    <span className='main_text heading_color_1'>{month}</span>
                                    <div className="sp_year-weekdays-grid">
                                        {weekdays.map((day, i) => (
                                        <div key={i} className="sp_year-weekday sub_text heading_color_1">{day}</div>
                                        ))}
                                    </div>
                                    <div className="sp_year-days-grid">
                                    {(() => {
                                        const days = [];
                                        const daysInMonth = getDaysInMonth(index, year);
                                        const startDay = new Date(year, index, 1).getDay();
                                        for (let i = 0; i < startDay; i++) {
                                            days.push(<div key={`empty-${i}`} className="sp_empty"></div>);
                                        }
                                        for (let day = 1; day <= daysInMonth; day++) {
                                            const date = new Date(`${month} ${day}, ${year}`);
                                            date.setHours(0, 0, 0, 0);
                                            let active = isActive(date)
                                            let blocked
                                            blocked = disabledDates?.includes(date.toISOString().toString())
                                            let enabled
                                            enabled = enabledDates?.includes(date.toISOString().toString())
                                            let today = new Date(new Date().setHours(0,0,0,0))
                                            let showPrice = isWeekend(date) ? data?.stay?.weekendPrice : data?.stay?.weekdayPrice
                                            if(customPricing){
                                                const price = getCustomPrice(date)
                                                if(price){
                                                    showPrice = price
                                                }  
                                            }
                                            days.push(
                                                <div onClick={() => onEditDay(day,date,active,blocked,showPrice)} key={day} className={`sp_year_day ${(!active && !enabled) ? 'sp_year-day-disable' : 'sp_year-day'} ${selectedDate?.toString() === date?.toString() ? 'sp_day_active' : ''} ${date?.toString() === today?.toString() && 'sp_calender_today'}`}>
                                                    <span className ={`content ${(!active && !enabled) ? 'heading_color_4' : 'heading_color_1'}`}>{day}</span>
                                                    {getDayEnquiriesYear(day,date,enquiries,active,blocked,showPrice)}
                                                </div>
                                            );
                                        }
                                        return days;
                                        })()}
                                    </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        )}
                        {activeTab === 'Week' && (
                            <div className="sp-week-calendar-container">
                                <div className="sp-week-calendar-header">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        <div onClick={handlePreviousWeek} className="d-flex flex-row justify-content-center align-items-center cursor">
                                            <img src={leftArrow} alt = 'leftArrow' />
                                        </div>
                                        <div className="sp_calendar_week_active">
                                            <span className="content text_color_1">Week-{weekNumber}</span>
                                        </div>
                                        <div  onClick={handleNextWeek} className="d-flex flex-row justify-content-center align-items-center cursor">
                                            <img src={rightArrow} alt = 'rightArrow' />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        <div onClick={handlePreviousMonth} className="d-flex flex-row justify-content-center align-items-center cursor">
                                            <img  src={leftArrow} alt = 'leftArrow' />
                                        </div>
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <span className="main_text heading_color_1">{format(currentDateNew, 'MMMM yyyy')}</span>
                                        </div>
                                        <div onClick={handleNextMonth}  className="d-flex flex-row justify-content-center align-items-center cursor">
                                            <img src={rightArrow} alt = 'rightArrow' />
                                        </div>
                                    </div>
                                </div>
                                <div className="sp-week-calendar-grid">
                                    {daysOfWeek?.map((day) =>  {
                                        let active = isActive(day)
                                        let blocked
                                        blocked = disabledDates?.includes(day.toISOString().toString())
                                        let enabled
                                        enabled = enabledDates?.includes(day.toISOString().toString())
                                        let today = new Date(new Date().setHours(0,0,0,0))
                                        let showPrice = isWeekend(day) ? data?.stay?.weekendPrice : data?.stay?.weekdayPrice
                                        if(customPricing){
                                            const price = getCustomPrice(day)
                                            if(price){
                                                showPrice = price
                                            }  
                                        }
                                        return(
                                            <div onClick={() => onEditDay(format(day, 'd'),day,active,blocked,showPrice)} className={`sp-week-calendar-day ${((active === false && enabled === false) || blocked === true) ? 'sp-week-calendar-day-dasable' : ''} ${selectedDate?.toString() === day?.toString() ? 'sp_day_active' : ''} ${day?.toString() === today?.toString() ? 'sp_calender_today' : ''}`} key={day}>
                                                <span className={`${((active === false && enabled === false) || blocked === true) ? 'heading_color_4' : 'heading_color_1'} content`}>{format(day, 'd')}</span>
                                                <span className={`${((active === false && enabled === false) || blocked === true) ? 'heading_color_4' : 'heading_color_1'} content`}>{format(day, 'EEE').toUpperCase()}</span>
                                                {getDayEnquiriesWeek(format(day, 'd'),day,enquiries,active,blocked,showPrice)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    {showEdit && (
                        <div className="sp_calendar_wrap_container_2 mt-2" id="sp_calendar_wrap_container_2">
                            <div className="w-100 d-flex flex-row justify-content-end cursor">
                                <CloseIcon onClick={handleEditClose}/>
                            </div>
                            <span className="sub_text heading_color_1">Customize your Date and Price</span>
                            {selectedDate && (
                                <>
                                    {!active && (
                                        <div className="sp-toggle-container w-100 mt-2">
                                            <span className="content text_color_1">Enable date</span>
                                            <div className="sp-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={enable}
                                                />
                                                <span className="sp-slider" onClick={onEnableChnage}/>
                                            </div>
                                        </div>
                                    )}
                                    <div className="sp-toggle-container w-100 mt-2">
                                        <span className="content text_color_1">Block date</span>
                                        <div className="sp-switch">
                                            <input
                                                type="checkbox"
                                                checked={block}
                                            />
                                            <span className="sp-slider" onClick={onBlockChnage}/>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="sp_calendar_input_wrapper w-100 mt-2">
                                <div className="sp_calendar_header_right_wrapper_input w-100">
                                    <Select
                                        label='Select type'
                                        name='event_type'
                                        options={eventTypes}
                                        value={ eventType ? { label :eventType, value : eventType} : ""}
                                        onChange={onChangeOptionEvent}
                                        placeholder='Select type'
                                        inputStyle={{boxShadow : '0px 4px 44px 0px #0000001A'}}
                                        style={{zIndex : '4'}}
                                        hideClearButton={true}
                                    />
                                </div>
                            </div>
                            <>
                                {eventType === 'stay' && (
                                    <>
                                        {selectedDate ? (
                                            <form onSubmit={onSubmitCustomPrice} className="w-100">
                                                <div className="sp_text_container w-100 mt-2 mb-2 p-1">
                                                    <label id='calendar_price' className="required mb-1"> Price </label>
                                                    <input
                                                        className="sp_input"
                                                        name='price'
                                                        value={price || ""}
                                                        onChange={(e)=>setPrice(e.target.value)}
                                                        placeholder='Enter price'
                                                        required={true}
                                                        pattern = "^[1-9]\d*$"
                                                    />
                                                    {errors?.price && <span className="text-danger"> {errors?.price} </span>}
                                                    {/* <NumberInput
                                                        id='calendar_price'
                                                        label='Price'
                                                        name='price'
                                                        value={price || ""}
                                                        onChange={(e)=>setPrice(e.target.value)}
                                                        placeholder='Enter price'
                                                        validate={(value) => (value ? '' : 'Price is required')}
                                                        errorMessage={errors?.price}
                                                        required={true}
                                                        title="Enter the price"
                                                    /> */}
                                                </div>
                                                <div className="w-100">
                                                    <button className="btn btn-primary sp_button w-100">Done</button>
                                                </div>
                                            </form>
                                        ) : (
                                            <form  onSubmit={onSubmit} className="w-100">
                                                <div className="w-100 mt-2 sp_text_container mb-2 mt-2">
                                                    <label id='calendar_weekdayPrice' className="required mb-1"> Weekday price </label>
                                                    <input
                                                        className="sp_input"
                                                        name='weekdayPrice'
                                                        value={formData?.weekdayPrice || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter price'
                                                        required={true}
                                                        pattern = "^[1-9]\d*$"
                                                    />
                                                    {errors?.weekdayPrice && <span className="text-danger"> {errors?.weekdayPrice} </span>}
                                                    {/* <NumberInput
                                                        id='calendar_weekdayPrice'
                                                        label='Weekday price'
                                                        name='weekdayPrice'
                                                        value={formData?.weekdayPrice || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter price'
                                                        validate={(value) => (value ? '' : 'Weekday price is required')}
                                                        errorMessage={errors?.weekdayPrice}
                                                        required={true}
                                                        title="Enter the price"
                                                    /> */}
                                                </div>
                                                <div className="w-100 sp_text_container mb-2 mt-2">
                                                    <label id='calendar_weekendPrice' className="required mb-1"> Weekend price </label>
                                                    <input
                                                        className="sp_input"
                                                        name='weekendPrice'
                                                        value={formData?.weekendPrice || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter price'
                                                        required={true}
                                                        pattern = "^[1-9]\d*$"
                                                    />
                                                    {errors?.weekendPrice && <span className="text-danger"> {errors?.weekendPrice} </span>}
                                                    {/* <NumberInput
                                                        id='calendar_weekendPrice'
                                                        label='Weekend price'
                                                        name='weekendPrice'
                                                        value={formData?.weekendPrice || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter price'
                                                        validate={(value) => (value ? '' : 'Weekend price is required')}
                                                        errorMessage={errors?.weekendPrice}
                                                        required={true}
                                                        title="Enter the price"
                                                    /> */}
                                                </div>
                                                <div className="w-100 sp_text_container mb-2 mt-2">
                                                    <label id='calendar_extraGuestFee mb-1'> Extra guest charge(Optional) </label>
                                                    <input
                                                        className="sp_input"
                                                        name='extraGuestFee'
                                                        value={formData?.extraGuestFee || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter guest fee'
                                                        pattern = "^[1-9]\d*$"
                                                    />
                                                    {/* <NumberInput
                                                        id='calendar_extraGuestFee'
                                                        label='Extra guest charge(Optional)'
                                                        name='extraGuestFee'
                                                        value={formData?.extraGuestFee || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter the price'
                                                    /> */}
                                                </div>
                                                <div className="w-100 sp_text_container mb-2 mt-2">
                                                    <label id='calendar_cleaningFee mb-1'> Cleaning fee(Optional) </label>
                                                    <input
                                                        className="sp_input"
                                                        name='cleaningFee'
                                                        value={formData?.cleaningFee || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter cleaning fee'
                                                        pattern = "^[1-9]\d*$"
                                                    />
                                                    {/* <NumberInput
                                                        id='calendar_cleaningFee'
                                                        label='Cleaning fee(Optional)'
                                                        name='cleaningFee'
                                                        value={formData?.cleaningFee || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter cleaning fee'
                                                    /> */}
                                                </div>
                                                <div className="w-100 sp_text_container mb-2 mt-2">
                                                    <label id='calendar_securityDeposit mb-1'> Security deposit(Optional) </label>
                                                    <input
                                                        className="sp_input"
                                                        name='securityDeposit'
                                                        value={formData?.securityDeposit || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter security deposit'
                                                        pattern = "^[1-9]\d*$"
                                                    />
                                                    {/* <NumberInput
                                                        id='calendar_securityDeposit'
                                                        label='Security deposit(Optional)'
                                                        name='securityDeposit'
                                                        value={formData?.securityDeposit || ""}
                                                        onChange={onChange}
                                                        placeholder='Enter security deposit'
                                                    /> */}
                                                </div>
                                                <div className="w-100 mb-2 mt-2">
                                                    <button className="btn btn-primary sp_button w-100">Done</button>
                                                </div>
                                            </form>
                                        )}
                                    </>
                                )}
                                {eventType === 'film' && (
                                    <span className="content text_color_1">Edit pricing will be coming soon</span>
                                )}
                                {eventType === 'tv' && (
                                    <span className="content text_color_1">Edit pricing will be coming soon</span>
                                )}
                                {eventType === 'event' && (
                                    <span className="content text_color_1">Edit pricing will be coming soon</span>
                                )}
                            </> 
                        </div>
                    )}
                </div>
            </div>
            {loader && <PageLoader/>}
        </div>
    )
}
export default React.memo(Calendar);