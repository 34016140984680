import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getPrioritySupportDetails, updatePrioritySupportTicket } from "../../services/api";
import Moment from "moment";
import { PageLoader } from "../../services/PageLoader";
import {Modal} from 'react-bootstrap'
import { useAuth } from "../../../auth";
import VerifyModal from "../../Modals/VerifyModal";

const PrioritySupportManagementList = () => {
	const {getSideBarCount} = useAuth()
	const [data, setData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [show,setShow] = useState(false);
	const [modalData,setModalData] = useState({});
	const [searchTerm, setSearchTerm] = useState('');
	const [showVerifyModal, setShowVerifyModal] = useState();
	const [resolveId, setResolveId] = useState("");

	const userColumns = [
		{
			field: "user_id", headerName: "User Id", width: 220,
			renderHeader: () => (
				<th className='min-w-150px'>User Id</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record?.row?.user_id}
				</div>
			),
		},
		{
			field: "ticketId", headerName: "Ticket Id", width: 152,
			renderHeader: () => (
				<th className='min-w-150px'>Ticket Id</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record?.row?.ticketId}
				</div>
			),
		},
		{
			field: "locationId", headerName: "Location ID", width: 120,
			renderHeader: () => (
				<th className='min-w-150px'>Location ID</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record?.row?.locationId || 'NA'}
				</div>
			),
		},
		{
			field: "topic",
			headerName: "Topic",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Topic</th>
			),
			renderCell: (params) => {
				return (
					<div className='text-dark fw-bold text-hover-primary fs-6'>
						{/* <img className="cellImg" src={params.row.img} alt="" /> */}
						{params?.row?.topic}
					</div>
				);
			},
		},
		// {
		// 	field: "message",
		// 	headerName: "Message",
		// 	width: 200,
		// 	renderHeader: () => (
		// 		<th className='min-w-150px'>Message</th>
		// 	),
		// 	renderCell: (record) => (
		// 		<div className='text-dark fw-bold text-hover-primary fs-6'>
		// 			{record?.row?.message}
		// 		</div>
		// 	),
		// },
		{
			field: "createdDate",
			headerName: "Phone Number",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Requested Date</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{Moment(record?.row?.createdDate).format('DD-MM-YYYY h:mm:ss a')}
				</div>
			),
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Status</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record?.row?.status}
				</div>
			),
		},
		{
			field: "resolvedDate",
			headerName: "Date",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Resolved Date</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record?.row?.status === "Resolved" ? Moment(record.row.resolvedDate).format('DD-MM-YYYY h:mm:ss a') : "--" }
				</div>
			),
		},
	];
	const getData = async () => {
		try{
			const resp = await getPrioritySupportDetails({searchTerm});
			setOriginalData(resp?.data);
			const temp = resp?.data?.map((item)=> {
				return {
					id : item?._id,
					ticketId : item?.enquiryId || item?.data?.id,
					user_id : item?.data?.user_id || 'NA',
					locationId : item?.data?.locationId || 'NA',
					topic : item?.data?.topic || "NA" ,
					// message : item?.data?.message || 'NA',
					createdDate : item?.createdAt,
					status : item?.status,
					resolvedDate : item?.updatedAt,
				}
			})
			setData(temp);
			setShowLoader(false)
		}catch(error){
			console.log(error?.response?.data?.message)
			setShowLoader(false)
		}
	}
	const handleView = (data) => {
		const temp = originalData.filter((each) => each?._id === data?.id )
		setModalData(temp[0]);
		setShow(true)
	}

	const handleResolve = async(id) => {
		try{
			const res = await updatePrioritySupportTicket(id);
			getData();
			getSideBarCount()
			alert(res?.data?.message);
		} catch (error) {
			console.log(error); 
			alert(error?.response?.data?.message);
		}
	}

	const actionColumn = [
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Action</th>
			  ),
			renderCell: (params) => {
				return (
					<div className="text-end">
						<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={()=>{
							handleView(params.row)
						}}>
							<KTIcon iconName='shield-search' className='fs-3'/>
						</div>
						{ params?.row?.status === "In progress" && 
							<div className=' mx-10 btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={() => {
								setShowVerifyModal(true);
								setResolveId(params?.row?.id);
							}}>
								<button className="btn btn-primary btn-active-success btn-flex btn-center btn-sm" > Resolve </button>
							</div>
						}
					</div>
				);
			},
		},
	];


	const prioritySupport = (each) => {
        return(
			<div className='card mb-5 mb-xl-10' >
				<div className='card-header cursor-pointer'>
					<div className='mb-3'>
						{/* begin::Text */}
						<div className='mt-3'>
						<span  className='fw-bold'>Type :</span> <span>{each?.type}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Ticket Id :</span> <span>{each?.enquiryId || each?.data?.id}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Status :</span> <span>{each?.status}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>User Id :</span> <span>{each?.data?.user_id}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Topic :</span> <span>{each?.data?.topic}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Message :</span> <span>{each?.data?.message}</span>
						</div>
						<div className=' mt-3'>
						<span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
						</div>
						{ each?.status === "Resolved" &&
							<div className='mt-3'>
							<span  className='fw-bold'>Resolved Date :</span> <span>{Moment(each.updatedAt).format('DD/MM/YYYY hh:mm a')}</span>
							</div>
						}  
						{/* end::Text */}
					</div>
				</div>
			</div>
        )
    }

	useEffect(() => {
		setShowLoader(true)
		getData()
	}, []);

	const handleSearch = async (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getData();
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchTerm])

	return (
		<div className="card">
			<div className="card-header border-0 pt-6">
				<div className='card-title'>
					{/* begin::Search */}
					<div className='d-flex align-items-center position-relative my-1'>
						<KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
						<input
							type='text'
							data-kt-user-table-filter='search'
							className='form-control form-control-solid w-250px ps-14'
							placeholder='Search'
							value={searchTerm}
							onChange={handleSearch}
						/>
					</div>
					{/* end::Search */}
				</div>
			</div>
			<div>
				{/* begin::Body */}
				<div className='card-body'>
					{/* begin::Table container */}
					<div className='table-responsive'>
						{/* begin::Table */}
						<div className="datatable">
							<DataGrid
								className="datagrid"
								rows={data}
								columns={userColumns.concat(actionColumn)}
								pageSize={50}
								rowsPerPageOptions={[9]}
								sx={{
									"& .MuiDataGrid-row:hover": {
										backgroundColor: "inherit"
									},
									"& .MuiDataGrid-cell": {
										borderBottom: "1px solid var(--bs-gray-100)"
									}

								}}
							/>
						</div>
						{/* end::Table */}
					</div>
					{/* end::Table container */}
				</div>
				{/* begin::Body */}
			</div>
			{showLoader && <PageLoader />}
			{show && (
				<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={() => setShow(false)}
				>
					<Modal.Header closeButton>
					<Modal.Title>Priority Request Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="d-flex flex-column">
							{prioritySupport(modalData)}
						</div>
					</Modal.Body>
				</Modal>
			)}
			{showVerifyModal && (
				<VerifyModal
					show={showVerifyModal}
					title="Support"
					body="Are you sure you want to resolve this issue ?"
					onHide={() => setShowVerifyModal(false)}
					OnSuccess={() => {
						handleResolve(resolveId);
						setShowVerifyModal(false);
					}}
				/>
			)}
		</div>
	);
};

export default PrioritySupportManagementList;
