import { useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { addCmsPage,updatePage, getPageDetails} from "../services/api";
import { PageLoader } from "../services/PageLoader";

const Createcoupon = () => {
  let location : any = useLocation();
  const params = useParams()
  const navigate = useNavigate();
  const pageId = params.pageId
  const [formData, setFormData] = useState<any>({
    pageTitle : location?.state?.locationId ? location?.state?.locationId : ''
  });
  const [showLoader, setShowLoader] = useState(false)
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getCoreDomainWithPath = (url : string) => {
    url = url.trim();
    // Add "https://" if the URL does not start with http or https, so it can be parsed
    if (!/^https?:\/\//.test(url)) {
        url = 'https://' + url;
    }
    try {
        const urlObject = new URL(url);
        const coreDomain = urlObject.hostname.replace(/^www\./, '');
        // Get path, query, and hash, and remove any trailing slashes from each part
        let pathWithQueryAndHash = urlObject.pathname.replace(/\/+$/, '') 
                                    + urlObject.search.replace(/\/+$/, '') 
                                    + urlObject.hash.replace(/\/+$/, '');
        // Combine core domain with the cleaned path, search, and hash
        let cleanedUrl = `${coreDomain}${pathWithQueryAndHash}`;
        // Remove any trailing slash from the entire URL
        cleanedUrl = cleanedUrl.replace(/\/+$/, '');
        return cleanedUrl;
    } catch (error) {
        alert("Invalid URL: Could not parse the URL.")
        return null;
    }
  };

  const submitForm = async (e : any) => {
    setShowLoader(true)
    try{
      e.preventDefault();
      const reqTitle = getCoreDomainWithPath(formData?.pageTitle);
      console.log("reqTitle", reqTitle);
      if (!reqTitle) return null
      const reqData = {...formData, pageTitle : reqTitle};
      // console.log("reqData", reqData);
      let res:any;
      if(pageId){
        res = await updatePage(reqData);
      }else{
        res = await addCmsPage(reqData);
      }
      setShowLoader(false)
      navigate('/apps/cms')
    } catch(error : any){
      console.log("error", error);
      console.log(error?.response?.data?.message)
      alert(error?.response?.data?.message)
    } finally {
      setShowLoader(false);
    }
  }

  // console.log("formData", formData);

  useEffect(() => {
    const getPage = async () => {
      setShowLoader(true)
      try{
        const res = await getPageDetails(pageId);
        setFormData(res?.data)
      }catch(error :any){
        console.log(error?.response?.data?.message)
      }
      setShowLoader(false)
    }
    if(pageId){
      getPage();
    }
  }, []);

  return (
    <div className="card">
      <form className="card-header border-0 pt-6" onSubmit={submitForm}>
        <div className="container">
          <div className="row">
          <div className="col-lg-6 mb-2">
            <div className="d-flex flex-column">
              <label className="form-label mb-3" htmlFor="pageTitle">
                Page Url<span className="required"></span>
              </label>
              <input
                required
                // disabled ={pageId || location?.state?.locationId ? true : false}
                type="text"
                value = {formData?.pageTitle}
                id="pageTitle"
                className="form-control form-control-lg form-control-solid"
                name="pageTitle"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2"></div>
          <div className="col-lg-6 mb-2">
            <div className="d-flex flex-column">
              <label className="form-label mb-3" htmlFor="metaTitle">
                Meta Title<span className="required"></span>
              </label>
              <input
                required
                type="text"
                value = {formData?.metaTitle}
                id="metaTitle"
                className="form-control form-control-lg form-control-solid"
                name="metaTitle"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2"></div>
          <div className="col-lg-6 mb-2">
            <div className="d-flex flex-column">
              <label className="form-label mb-3" htmlFor="metaDescription">
                Meta Description<span className="required"></span>
              </label>
              <textarea
                required
                rows={5}
                cols={55}
                value = {formData?.metaDescription}
                id="metaDescription"
                className="form-control form-control-lg form-control-solid"
                name="metaDescription"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2"></div>
          <div className="col-lg-6 mb-2">
            <div className="d-flex flex-column">
              <label className="form-label mb-3" htmlFor="metaKeywords">
                Meta Keywords
              </label>
              <input
                type="text"
                value = {formData?.metaKeywords}
                placeholder="Write Keywords with comma (',') seperated"
                id="metaKeywords"
                className="form-control form-control-lg form-control-solid"
                name="metaKeywords"
                onChange={onChange}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary mb-5"
            >
              {pageId ? "Update Page" : 'Create Page'}
            </button>
          </div>
          </div>
        </div>
      {showLoader && <PageLoader />}
      </form>
    </div>
  );
};

export default Createcoupon;
