/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
// import LogList from '../../modules/apps/auditLogs/list/LogsList'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import { getDashboardDetails} from '../../modules/apps/services/api'

const DashboardPage: FC = () => {
  const [data,setData] = useState<any>({})
  const getData = async() => {
    try{
      const resp = await getDashboardDetails()
      setData(resp?.data)
    }catch(error){
      console.log(error)
    }
  }
  useEffect(() => {
      getData();
  }, []);

  return (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <Link to={'/apps/location-management/requests'}>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Listing Requests'
            color='#3B8FF3'
            img={toAbsoluteUrl('/media/misc/pattern-2.png')}
            headerCount={data?.listingRequests}
            bodyCount={0}
            bodyPercentage={0}
            // bodyCount={allLocationCount - approvedLocationCount}
            // bodyPercentage={Number(approvedLocationCount / allLocationCount * 100).toFixed(2)}
          />
        </Link>
        <Link to={'/apps/location-management/approved'}>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Approved Listings'
            color='#198745'
            img={toAbsoluteUrl('/media/patterns/pattern-1.jpg')}
            headerCount={data?.approved}
            bodyCount={0}
            bodyPercentage={0}
            // bodyCount={allLocationCount - approvedLocationCount}
            // bodyPercentage={Number(approvedLocationCount / allLocationCount * 100).toFixed(2)}
          />
        </Link>
      </div>
      {/* end::Col */}
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <Link to={'/apps/location-management/reviewed'}>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Listing for Approval'
            color='#FFC107'
            img={toAbsoluteUrl('/media/misc/vecto/1.png')}
            headerCount={data?.listingVerified}
            bodyCount={0}
            bodyPercentage={0}
            // bodyCount={allLocationCount - approvedLocationCount}
            // bodyPercentage={Number(approvedLocationCount / allLocationCount * 100).toFixed(2)}
          />
        </Link>
        <Link to={'/apps/location-management/rejected'}>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Rejected Listings'
            color='#CC3333'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            headerCount={data?.listingRejected}
            bodyCount={0}
            bodyPercentage={0}
            // bodyCount={allLocationCount - approvedLocationCount}
            // bodyPercentage={Number(approvedLocationCount / allLocationCount * 100).toFixed(2)}
          />
        </Link>
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
        <Link to={'/apps/users/list'} state ={{type : 'user'}}>
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            color='#080655'
            img=''
            description='Active Users'
            icon={false}
            stats={data?.users}
            labelColor='dark'
            textColor='gray-300'
          />
        </Link>
        <Link to={'/apps/users/list'} state ={{type : 'host'}}>
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            color='#7239ea'
            img=''
            description='Active Hosts'
            icon={false}
            stats={data?.hosts}
            labelColor='dark'
            textColor='gray-300'
          />
        </Link>
        {/* <ListsWidget26 className='h-lg-50' /> */}
      </div>
      {/* end::Col */}

      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-100 mb-5 mb-xl-10' total={data?.totalLeads} leads = {data?.leadsByType}/>
      </div>

      <div className='col-xl-6'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' premium={data?.premium} standard={data?.standard} basic={data?.basic}/>
      </div>
      {/* begin::Col */}
      {/* <div className='col-xxl-6'>
        <EngageWidget10 className='h-md-100' />
      </div> */}
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
    {/* end::Row */}
    {/* <div className='m-2'>
      <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>All logs</h1>
    </div>
    <div className='mb-5' style={{height : '400px',overflowY : 'auto'}}>
      <LogList/>
    </div> */}
    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
)
  }
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
