import { useEffect, useState ,useRef } from "react";
import { useNavigate,useParams } from "react-router-dom";
import JoditEditor from 'jodit-react';
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import Rating from '@mui/material/Rating';
import {getTestimonialsDetails, updateTestimonials} from "../../apps/services/api";

const EditTestimonials = () => {
    const editor = useRef(null);
    const params = useParams()
    const testimonialId = params.id
    const navigate = useNavigate();
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [formData,setFormData] = useState<any>({
        name : '',
        location : '',
        content : ''
    })
    const [error,setError] = useState<boolean>(false)
    const [errorContent,setErrorContent] = useState<boolean>(false)

    const getData = async()=> {
        try{
            const resp = await getTestimonialsDetails(testimonialId)
            setFormData(resp?.data)
            setShowLoader(false)
        }catch(error:any){
            console.log(error?.response?.data?.message)
            setShowLoader(false)
        }
    }

    const onchange = (e) => {
        const {name,value} = e.target
        const re = /^[a-zA-Z ]*$/
    if (value === "" || re.test(value))
        setFormData({...formData,[name] : value})
    }

    const changeContent = (value) => {
        setFormData({...formData,content : value})
        if(value){
            setErrorContent(false)
        }
    }

    const submitForm = async(e) => {
        e.preventDefault()
        try{
            if(!formData?.rating){
                setError(true)
                return null
            }
            if(!formData?.content || formData?.content === '<p><br></p>'){
                setErrorContent(true)
                return null
            }
            var html = document.createElement('div')
            html.innerHTML = formData.content;
            const text : any = html.textContent
            if(text.length > 350){
                setModelBody("Only 350 characters allowed");
                 setShow(true)
                 return null
            }
            setShowLoader(true)
            const resp = await updateTestimonials(formData)
            setFormData({})
            setModelBody(resp?.data?.message);
            setShow(true)
            setShowLoader(false)
            navigate('/apps/testimonials/list')
        }catch(error:any){
            console.log(error?.response?.data?.message)
            setShowLoader(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    useEffect(()=>{
        setShowLoader(true)
        getData()
    },[]);

    return(
        <form className="d-flex flex-column" onSubmit={submitForm}>
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="name">
                    Name<span className="required"></span>
                </label>
                <input
                    type="text"
                    value = {formData?.name}
                    required
                    id="name"
                    className="form-control form-control-lg form-control-solid"
                    name="name"
                    onChange={onchange}
                />
                </div>
            </div>
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="location">
                    Location<span className="required"></span>
                </label>
                <input
                    type="text"
                    value = {formData?.location}
                    required
                    id="location"
                    className="form-control form-control-lg form-control-solid"
                    name="location"
                    onChange={onchange}
                />
                </div>
            </div>
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="location">
                        Type<span className="required"></span>
                    </label>
                    <select value = {formData?.type} name="type" required onChange={onchange} id="kt_ecommerce_select2_country" className="form-select form-select-solid" data-kt-ecommerce-settings-type="select2_flags">
                        <option value='' disabled selected>Select type</option>
                        <option value='film'>Film</option>
                        <option value='event'>Event</option>
                        <option value='stay'>Stay</option>
                    </select>
                </div>
            </div>
            <div className="col-lg-6 mb-5">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="rating">
                    Rating<span className="required"></span>
                </label>
                {error && <span style={{color : 'red'}}>Rating is required</span>}
                {(formData?.rating || formData?.rating === null) && (
                    <Rating
                        name="rating"
                        value={formData?.rating}
                        onChange={(event, newValue) => {
                        setFormData({ ...formData, rating: newValue });
                        if(newValue){
                            setError(false)
                        }
                        }}
                      />
                )}
                  <style>{"\
                    .jodit-status-bar{\
                        display:none;\
                    }\
                    "}</style>
                </div>
            </div>
            <div className="col-lg-12 mb-5">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="content">
                    Content<span className="required"></span>
                </label>
                {errorContent && <span style={{color : 'red'}}>Content is required</span>}
                <JoditEditor
                    ref={editor}
                    value={formData?.content}
                    onChange={newContent => changeContent(newContent)}
                />
                </div>
            </div>
            <div className="align-self-end">
                <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    >
                    Submit
                </button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Testimonials</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
        </form>
    )
}
export default EditTestimonials;
