import { useEffect, useState } from "react";
import {Link,useNavigate} from 'react-router-dom'
import { KTIcon } from "../../../../_metronic/helpers";
import { getBlogs,deleteBlog} from "../services/api";
import Moment from 'moment';
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import { useAuth } from "../../auth";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import VerifyModal from "../Modals/VerifyModal";
const BlogsList = () => {
    const {currentUser} = useAuth()
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const noOfItems = 9;
    const [page, setPage] = useState<number>(1)
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [showLoader, setShowLoader] = useState(false);
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showVerify, setShowVerify] = useState(false);
    const [action,setAction] = useState('')
    const [actionData,setActionData] = useState({})
    const getBlogsData = async () => {
        setShowLoader(true);
        try{
            const res = await getBlogs(page,noOfItems,searchTerm);
            setData(res.data.blogs)
            setDataCount(res?.data?.count)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    const verifyDelete = (data) => {
        setAction('delete')
        setActionData(data)
        setModelBody('Are you sure, Want to delete blog ?')
        setShowVerify(true)
    }
    const verifyUpdate = (data) => {
        setAction('update')
        setActionData(data)
        setModelBody('Are you sure, Want to update blog ?')
        setShowVerify(true)
    }
    const confirmVerify = () => {
        if(action === 'delete'){
            handleDeleteBlog(actionData)
        }
        if(action === 'update'){
            handleUpdateBlog(actionData)
        }
    }
    const handleDeleteBlog = async (data) => {
        setShowLoader(true)
        try {
            const res = await deleteBlog(data.blogId)
            setModelBody(res?.data?.message)
            setShow(true)
            getBlogsData();
        } catch(error : any) {
            console.log(error?.response?.data?.message)
        }
        setAction('')
        setActionData({})
        setShowLoader(false)
    }
    const handleUpdateBlog = (data) => {
        setAction('')
        setActionData({})
        navigate(`/apps/blogs-management/update/${data.blogId}`)
    }
    const previewBlog = (data) => {
        window.open(`${process.env.REACT_APP_SPOTLET_BLOGS_URI}/${data?.title?.toLowerCase()?.replaceAll(" ", "-")}/${data?.blogId}/`, '_blank')
    }
    useEffect(() => {
        getBlogsData();
    }, []);
    useEffect(()=> {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        getBlogsData();
    }, [page]);
    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getBlogsData();
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchTerm])
    const hanldeSearch = (e : any) => {
        setSearchTerm(e.target.value)
      }
    const handleClose = () => {
        setShow(false)
    }
    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Blog'
                            onChange={hanldeSearch}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {currentUser?.permissions?.Blog_management?.write && (
                    <div className="card-toolbar">
                        <Link to = {"/apps/blogs-management/create"}>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add New Blog
                            </button>
                        </Link>
                    </div>
                )}
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Title</th>
                                    <th className='min-w-100px'>Created By</th>
                                    <th className='min-w-100px'>Created date</th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.title}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.createdBy}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-end">
                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        onClick={() => previewBlog(item)}
                                                        title="View Blogs">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div>
                                                    {currentUser?.permissions?.Blog_management?.write && (
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            onClick={() =>verifyUpdate(item)}
                                                            title="Update">
                                                            Update
                                                        </div>
                                                    )}
                                                     {currentUser?.permissions?.Blog_management?.delete && (
                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        onClick={() =>verifyDelete(item)}
                                                        title="Delete">
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </div>
                                                     )}
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(dataCount/noOfItems)} variant="outlined" shape="rounded" color="primary" onChange={(e,value)=>setPage(value)} />
                    </Stack>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Blogs</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showVerify && (
                <VerifyModal
                show={showVerify}
                title='Blogs'
                body={modelBody}
                onHide={() => setShowVerify(false)}
                OnSuccess={() => {
                    setShowVerify(false);
                    confirmVerify()
                }}
                />
            )}
            {showLoader && <PageLoader />}
        </div>

    );
};

export default BlogsList;
