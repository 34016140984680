import { useState ,useRef } from "react";
import { useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import {createBlog,uploadLocationPics} from "../../apps/services/api";
import { useAuth } from "../../auth";
const FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
}

const CreateBlogs = () => {
    const {currentUser} = useAuth()
    const editor = useRef(null);
    const navigate = useNavigate();
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [imageFile, setImageFile] = useState<any>(null);
    const [formData,setFormData] = useState<any>({
        blogId : '',
        title : '',
        image : '',
        imageAlt : '',
        content : '',
        views : 0,
        likes : {},
        comments : {},
        createdBy : currentUser?._id,
    })
    const [errorContent,setErrorContent] = useState<boolean>(false)
    const onClickDelete = () => {
        setFormData({
            ...formData,
            image : "",
        })
    }
    const handleImageChange = async(e, listingId) => {
        const file = e.target.files[0];
        if (!file) {
            e.target.value = null;
            alert(`No file detected`);
            return null;
        }
        if (!(FILE_TYPES.includes(file.type))) {
            e.target.value = null;
            alert(`Only JPEG, JPG and PNG files allowed for upload.`);
            return null;
        }

        const imageUrl = URL.createObjectURL(file); // Create URL for the image
        setImageFile(file); // Store the file for uploading later
        setFormData({
            ...formData,
            image : imageUrl,
        })
        e.target.value = null;
    };
    const onchange = (e) => {
        const {name,value} = e.target
        setFormData({...formData,[name] : value})
    }

    const changeContent = (value) => {
        setFormData({...formData,content : value})
        if(value){
            setErrorContent(false)
        }
    }

    const onUploadImage = async () => {
        const file = await urltoFile(
            URL.createObjectURL(imageFile),
            imageFile.name,
            "text/plain"
        );
        const mainImages = new FormData();
        mainImages.append("pic", file);
        mainImages.append("fileName", imageFile.name);
        mainImages.append("locationId", 'blogs');
        mainImages.append("type",'events');
        try{
            const response : any = await uploadLocationPics(mainImages);
            setFormData({
                ...formData,
                image : response.data.url,
            })
            return {...formData, image : response.data.url}
        }catch(error : any){
            console.log(error);
            alert(error?.response?.data?.message);
        }
    }

    const submitForm = async(e) => {
        e.preventDefault()
        try{
            if(!formData?.content || formData?.content === '<p><br></p>'){
                setErrorContent(true)
                return null
            }
            var html = document.createElement('div')
            html.innerHTML = formData.content;
            const text : any = html.textContent
            setShowLoader(true)
            let reqFormData
            if (imageFile) {
                reqFormData = await onUploadImage();
            } else {
                alert("Banner image required");
                return null
            }
            const resp = await createBlog(reqFormData)
            setFormData({})
            console.log(resp?.data)
            setModelBody(resp?.data?.message);
            setShow(true)
            setShowLoader(false)
        }catch(error:any){
            console.log(error?.response?.data?.message)
            setShowLoader(false)
        }
    }

    const handleClose = () => {
        setShow(false)
        navigate('/apps/blogs-management/list')
    }

    return(
        <form className="d-flex flex-column" onSubmit={submitForm}>
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="name">
                    Title<span className="required"></span>
                </label>
                <input
                    type="text"
                    value = {formData?.title}
                    required
                    id="title"
                    className="form-control form-control-lg form-control-solid"
                    name="title"
                    onChange={onchange}
                />
                </div>
            </div>
            {!formData?.image ? (
                <div className="col-lg-6 mb-3">
                    <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="name">
                        Banner image<span className="required"></span>
                    </label>
                    <input
                        type="file"
                        multiple={false}
                        data-max_length="20"
                        className="dashboard_recent_gallery_upload_input"
                        id="upload_image_file"
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={ (e) => {
                            handleImageChange(e, formData?.listing);
                        }}
                        required={true}
                    />
                    </div>
                </div>
            ) : (
                <div className="col-lg-3 mb-3">
                    <label className="form-label mb-3" htmlFor="name">
                        Banner image<span className="required"></span>
                    </label>
                    <div className="w-100 d-flex flex-row">
                        <img src={formData?.image} alt="image" style={{height : '100%',width : '100%'}}/>
                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            onClick={onClickDelete}
                            style={{height :'fit-content'}}
                            title="delete">
                            Delete
                        </div>
                    </div>
                </div>
            )}
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="name">
                    Image alternative title<span className="required"></span>
                </label>
                <input
                    type="text"
                    value = {formData?.imageAlt}
                    required
                    id="imageAlt"
                    className="form-control form-control-lg form-control-solid"
                    name="imageAlt"
                    onChange={onchange}
                />
                </div>
            </div>
            <div className="col-lg-12 mb-5">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="content">
                    Content<span className="required"></span>
                </label>
                {errorContent && <span style={{color : 'red'}}>Content is required</span>}
                <JoditEditor
                    ref={editor}
                    value={formData?.content}
                    onChange={newContent => changeContent(newContent)}
                />
                </div>
            </div>
            <div className="align-self-end">
                <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    >
                    Submit
                </button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Blogs</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
        </form>
    )
}
export default CreateBlogs;