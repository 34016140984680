import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom'
import { KTIcon } from "../../../../../_metronic/helpers";
import { getPages,getPageDetails,getPagesCount,deletePage} from "../../services/api";
import { PageLoader } from "../../services/PageLoader";
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useAuth } from "../../../auth";

const PageList = () => {
    let location : any = useLocation();
    const navigate = useNavigate();
    const {currentUser} = useAuth()
    const [data, setData] = useState<any>([]);
    const [count,setCount] = useState<any>(0)
    const [searchData, setSearchData] = useState<any>('');
    const [showLoader, setShowLoader] = useState(false);
    const [page, setPage] = useState(1);

    const getPagesData = async () => {
        setShowLoader(true)
        try{
            const countResp = await getPagesCount({searchTerm: searchData});
            setCount(Math.ceil(countResp?.data?.count/50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            const res = await getPages({page,items : 50, searchTerm: searchData});
            setData(res?.data)
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }
        setShowLoader(false)
    }
    const getPage = async (pageId) => {
        try{
          const res = await getPageDetails(pageId);
          if(res?.data){
            navigate(`../update_page/${pageId}`)
          }else{
            navigate("../new_page",{ state: {locationId : pageId} });
          }
        }catch(error :any){
          console.log(error?.response?.data?.message)
        }
      }

    useEffect(() => {
        getPagesData();
        if(location?.state?.locationId) getPage(location?.state?.locationId)
    }, [page]);
    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getPagesData();
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchData])

    const deletingPage = async (id) => {
        try{
            if(window.confirm('Are you sure you want to delete ?') == true){
                const res = await deletePage(id)
                console.log(res?.data)
                alert(res?.data?.message)
                getPagesData();
            }
        }catch(error :any){
            console.log(error?.response?.data?.message)
        }
    }

    const handleChange = async(value) => {
        try{
            // const resp = await getPages({page : value,items : 50, searchTerm: searchData});
            // setData(resp?.data?.data);
            setPage(value);
        }catch(error){
            console.log(error)
        }
    };

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Page'
                            value={searchData}
                            onChange={(e)=>setSearchData(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {currentUser?.permissions?.Cms_management?.write && (
                <div className="card-toolbar">
                    <Link to = {"/apps/cms/new_page"}>
                        <button type='button' className='btn btn-primary'>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add New Page
                        </button>
                </Link>
        </div>
                )}
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Page Url</th>
                                    <th className='min-w-100px'>Created Date</th>
                                    <th className='min-w-100px'>Updated Date</th>      
                                    <th className='min-w-100px'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.pageTitle}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {Moment(item?.updatedAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title="View Locations">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div> */}
                                                    {/* <Link to ={`../job-view/${item._id}`} >
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View">
                                                            View
                                                        </div>
                                                    </Link> */}
                                                    {currentUser?.permissions?.Cms_management?.write && (
                                                    <Link to ={`../update_page/${item._id}`} >
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="Update">
                                                            <KTIcon iconName='pencil' className='fs-3'/>
                                                        </div>
                                                    </Link>
                                                    )}
                                                    {currentUser?.permissions?.Cms_management?.delete && (
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() => deletingPage(item._id)}
                                                            title="Delete page">
                                                            <KTIcon iconName='trash' className='fs-3' />
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
                {count && (
                    <div style={{display : 'flex',flexDirection : 'row-reverse' ,marginRight : "12px"}}>
                        <Stack spacing={2}>
                            <Pagination count={count} variant="outlined" shape="rounded" color="primary"
                            onChange={(event,value)=> {
                                handleChange(value);
                            }
                            }/>
                        </Stack>
                    </div>
                )}
            </div>
            {showLoader && <PageLoader />}
        </div>

    );
};

export default PageList;
