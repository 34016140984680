import { useState,useEffect} from "react";
import {some, isEqual} from 'lodash';
import info from "../../../../../_metronic/assets/images/i.svg";
import "./ListYourSpace"
import VerifyModal from "../../Modals/VerifyModal";
// import { deleteFilesFromBucket } from "../../services/api";

const INITIAL_VALUES = {
    name : '',
    length : '',
    height : '',
    width : '',
    description : "",
    size : "",
    area_type : "Sq.Ft",
}

const Features = ({data,handleBack,handleNext, editChanges}) => {
    const [allFeaturesData,setAllFeaturesData] = useState([]);
    const [formData, setFormData] = useState(INITIAL_VALUES);
    const [showForm, setShowForm] = useState(true);
    const [editIndex, setEditIndex] = useState(null);
    const [newGallary, setNewGallary] = useState({});
    const [newOriginalGallary, setNewOriginalGallary] = useState({});
    const [show, setShow] = useState(false);
    const [modalBody, setModalBody] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [deleteIndex, setDeleteIndex] = useState('');
    // const [deleteFiles, setDeleteFiles] = useState([]);

    const onChangeInput = (e) => {
        const {name,value } = e.target
        setFormData({...formData, [name]:value})
    }
    const onSubmitForm = (e) => {
        e.preventDefault();
        if (editIndex || editIndex === 0) {

             //rename in gallary
             if(Object.keys(newGallary).length > 0){
                const name = formData.name
                const oldName = allFeaturesData[editIndex].name
                setNewGallary((prev) => { return {}})
                setNewOriginalGallary((prev) => { return {}})
                Object.keys(newGallary).map((folderName) => {
                    if(folderName === oldName) {
                        setNewGallary((prev) => {
                            return {
                                ...prev,
                                [name] : newGallary[folderName]
                            }
                        })
                    }else{
                        setNewGallary((prev) => {
                            return {
                                ...prev,
                                [folderName] : newGallary[folderName]
                            }
                        })
                    }
                })

                Object.keys(newOriginalGallary).map((folderName) => {
                    if(folderName === oldName) {
                        setNewOriginalGallary((prev) => {
                            return {
                                ...prev,
                                [name] : newOriginalGallary[folderName]
                            }
                        })
                    }else{
                        setNewOriginalGallary((prev) => {
                            return {
                                ...prev,
                                [folderName] : newOriginalGallary[folderName]
                            }
                        })
                    }
                })
            }

            allFeaturesData.splice(editIndex, 1, formData)
            setAllFeaturesData([...allFeaturesData])
        }else{
            setAllFeaturesData([...allFeaturesData, formData])
        }
        setEditIndex(null)
        setFormData(INITIAL_VALUES)
        setShowForm(false);
    }
    const onClickDiscard = () => {
        if (allFeaturesData.length > 0) {
            setEditIndex(null)
            setShowForm(false)
        }
    }
    // const fetchRequiredImageUrlsforDeletion = (name) => {
    //     const imgs = newGallary?.[name] ? [...newGallary?.[name]] : [];
    //     const oImgs = newOriginalGallary?.[name] ? [...newOriginalGallary?.[name]] : [];
    //     const reqImgs = [...imgs, ...oImgs];
    //     const reqUrls = [];
    //     if (reqImgs) reqImgs?.forEach((each) => reqUrls.push(each?.image));
    //     setDeleteFiles([...reqUrls]);
    // }

    const onClickDelete = () => {
        const deletedRoom = allFeaturesData.splice(deleteIndex, 1);
        const reqRoom = deletedRoom?.[0]?.name;
        // fetchRequiredImageUrlsforDeletion(reqRoom);
        delete newGallary?.[reqRoom];
        delete newOriginalGallary?.[reqRoom];

        setAllFeaturesData([...allFeaturesData])
        setFormData(INITIAL_VALUES)
        if (allFeaturesData.length === 0) setShowForm(true)
    }
    const onVerifyDelete = (index) => {
        setDeleteIndex(index)
        setModalTitle('Rooms/Spaces')
        setModalBody('Are you sure, You want to delete ?')
        setShow(true)
    }
    const onClickEdit = (index) => {
        setFormData({size : "", area_type : "Sq.Ft", ...allFeaturesData[index]})
        setEditIndex(index)
        setShowForm(true)
    }
    // const deleteImages = async(files) => {
    //     try{
    //         await deleteFilesFromBucket(files);
    //         return "success"
    //     } catch(error) {
    //         console.log(error?.response?.data?.message);
    //         alert(`${error?.response?.data?.message || "Some error occured"}`);
    //         return null
    //     }
    // }
    const handleSave = () => {
        // if (deleteFiles?.length > 0) {
        //     const res = deleteImages(deleteFiles);
        //     if (!res) return null
        // }
        handleNext({features : allFeaturesData,  images : newGallary, original_images : newOriginalGallary})
    }
    useEffect(() => {
        if(data?.features?.length > 0){
            setAllFeaturesData([...data?.features])
            setShowForm(false)
        }
        if(data?.images){
            setNewGallary({...data.images})
        }
        if(data?.original_images){
            setNewOriginalGallary({...data.original_images})
        }
    },[data])

    const checkObjectExists = (objectToCheck) => {
        return some(editChanges?.changed , obj => isEqual(obj, objectToCheck));
    };

     const getDeletedObjects = () => {
        return(
                editChanges?.deleted.map((details, index) => {
                    return(
                        <div className="feature-card mt-2">
                                <div className="feature-card-1" >
                                    <div>
                                        <p className="feature-card-details"> {details.name} </p>
                                    </div>
                                </div>
                                <div>
                                    <p className="feature-card-details">
                                        {details?.length && `${details.length}`}{details?.length && <span className="featureSmallText">L</span>}
                                        {details?.length && details.width && " x "}
                                        {details?.width && `${details.width}`}{details?.width && <span className="featureSmallText">W</span>}
                                        {details?.width && details.height && " x "}
                                        {details?.height && ` ${details.height}`}{details?.height && <span className="featureSmallText">H</span>}
                                    </p>
                                </div>
                                {
                                    details?.size && 
                                    <div> 
                                            <p className="feature-card-details m-0"> Size - { `${details?.size}  ${details?.area_type}`  } </p>
                                    </div>
                                }
                                <div>
                                    <p className="feature-card-details mt-2">{details?.description}</p>
                                </div>
                            </div>
                    )
                } )
        )
     } 

    return(
        <>
        <div className="locationtype-page container-fluid " >
        <h1 className="locationtype-head" > Rooms/Spaces{' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Describe rooms uniquely, creating an inviting narrative in brief."
                >
                    <img src={info} />
                </a>
            </h1>
            <div className="tabCard row" >
            <div className="col-lg-8">
                    {showForm && (
                        <form className = "feature-form" onSubmit = {onSubmitForm} >
                            <div style={{maxWidth: "600px"}}>
                                <div className="d-flex flex-column mb-3" >
                                    <label > Name of the Room/Space<span className="required">*</span></label>
                                    <input 
                                    className="textFiled feature-input" 
                                    type = "input" 
                                    placeholder = "Eg: Bedroom, LivingArea, Dining Hall"
                                    required 
                                    onChange={onChangeInput}
                                    name = "name"
                                    value = {formData.name}
                                    />
                                </div>
                                <div className="row">
                                    <span className="mb-2"> Dimensions of the Room/Space</span>
                                        <div className="d-flex flex-column  mt-2 col-lg-4"> 
                                            <label > Length </label>
                                            <input 
                                            // required 
                                            type = "number" 
                                            min = {1} 
                                            placeholder = "Length in Ft"  
                                            className="textFiled feature-small-input"
                                            onChange={onChangeInput}
                                            name = "length"
                                            value = {formData.length}
                                            />
                                        </div>
                                        <div className="d-flex flex-column mt-2 col-lg-4"> 
                                            <label > Width </label>
                                            <input 
                                            // required 
                                            type = "number" 
                                            min = {1} 
                                            name = "width"
                                            placeholder = "Width in Ft"  
                                            className="textFiled feature-small-input "
                                            onChange={ onChangeInput}
                                            value = {formData.width}
                                            />
                                        </div>
                                        <div className="d-flex flex-column mt-2 col-lg-4"> 
                                            <label > Height   </label>
                                            <input 
                                            type = "number" 
                                            min = {1} 
                                            placeholder = "Height in Ft"  
                                            className="textFiled feature-small-input" 
                                            onChange={onChangeInput}
                                            name = "height"
                                            value = {formData.height}
                                            />
                                        </div>
                                </div>
                                <div className="mb-2 mt-2 d-flex flex-column">
                                    <label htmlFor="room_size" className="m-0">
                                        Size
                                    </label>
                                    <div class="buttonIn">
                                        <input
                                            type="number"
                                            min='1'
                                            placeholder="00"
                                            id="room_size"
                                            name="size"
                                            className="textFiled feature-input"
                                            style={{width : '100%'}}
                                            onChange={onChangeInput}
                                            value={formData?.size}
                                        />
                                        <div className='propertySizeButtonContainer'>
                                        <button type="button" onClick={()=> setFormData({...formData,area_type : 'Sq.Ft'})} className={formData.area_type === 'Sq.Ft' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Ft</button>
                                        <button type="button" onClick={()=> setFormData({...formData,area_type : 'Sq.Mts'})} className={formData.area_type === 'Sq.Mts' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Mts</button>
                                        <button type="button" onClick={()=> setFormData({...formData,area_type : 'Sq.Yds'})} className={formData.area_type === 'Sq.Yds' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Yds</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-3" >
                                    <label > Description</label>
                                    <textarea 
                                    className="basicInfoDescription feature-text-area" 
                                    // type = "input" 
                                    placeholder = "Room/Space description"
                                    onChange={onChangeInput}
                                    name = "description"
                                    value = {formData.description}
                                    rows = {4}
                                    // cols = {55}
                                    ></textarea>
                                </div>
                                <div className="d-flex flex-row align-center mt-3" >
                                <button className="btn btn-primary feature-buttons" type="submit" > Save   </button>
                                <button className="btn btn-outline-secondary feature-buttons" type="button" onClick={onClickDiscard} > Discard </button>
                                </div>
                            </div>
                    </form>
                    ) }
                </div>
                <div className="col-lg-4" ></div>
                <div className="col-lg-8">
                    {
                    allFeaturesData.length > 0 && 
                    allFeaturesData.map((details,index) => {
                        return (
                            <div className="feature-card mt-2">
                                {editChanges?.changed?.length > 0 && ( checkObjectExists(details) ? <span className="text-danger" > (Edited / Created) </span> : null ) }
                                <div className="feature-card-1" >
                                    <div>
                                        <p className="feature-card-details"> {details.name} </p>
                                    </div>
                                    <div className="feature-btns-container">
                                        <button type="button" className="btn btn-primary  feature-buttons" onClick={() => onClickEdit(index)} > <i class="bi bi-pencil"></i> </button> 
                                        <button type="button" className="btn btn-outline-secondary  feature-buttons" onClick={() => onVerifyDelete(index)}  > <i class="bi bi-trash"></i> </button>
                                    </div>
                                </div>
                                <div>
                                    <p className="feature-card-details">
                                        {details?.length && `${details.length}`}{details?.length && <span className="featureSmallText">L</span>}
                                        {details?.length && details.width && " x "}
                                        {details?.width && `${details.width}`}{details?.width && <span className="featureSmallText">W</span>}
                                        {details?.width && details.height && " x "}
                                        {details?.height && ` ${details.height}`}{details?.height && <span className="featureSmallText">H</span>}
                                    </p>
                                </div>
                                {
                                    details?.size && 
                                    <div> 
                                            <p className="feature-card-details m-0"> Size - { `${details?.size}  ${details?.area_type}`  } </p>
                                    </div>
                                }
                                <div>
                                    <p className="feature-card-details mt-2">{details?.description}</p>
                                </div>
                            </div>
                        )
                    } )
                    }
                </div>
                {
                    editChanges?.deleted?.length > 0 && (
                        <div className="col-lg-8 card p-2 mt-2 bg-secondary" >
                            <h5 className="text-danger"> Edited / Removed </h5>
                            {getDeletedObjects()}
                        </div>
                    )
                }
                <div>
                    { !showForm && <button type="button" onClick = {() => {
                        setFormData(INITIAL_VALUES)
                        setShowForm(true)} }  
                        className="btn btn-primary mt-2" style={{width:"150px"}}    > 
                    Add New 
                    </button>}
                </div>
            </div>
        </div>
        <div className="controlContainer">
        <div>
            <button
            type="button"
            class="btn btn-outline-danger"
            onClick={handleBack}
            >
                Back
            </button>
        </div>
        <div>
            <button
            type="button"
            className="btn btn-primary"
            
            onClick={handleSave}
            disabled = {allFeaturesData.length > 0 ? false : true}
            >
                Save & Continue
            </button>
        </div>
    </div>
    {show && (
            <VerifyModal
                show={show}
                title={modalTitle}
                body={modalBody}
                onHide={()=>setShow(false)}
                OnSuccess={()=>{
                    setShow(false)
                    onClickDelete()
                }}
             />
        )}
        </>
    )
}
export default Features