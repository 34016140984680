import { useEffect, useState } from "react";
import { getAllConversation } from "../../services/api";
import { PageLoader } from "../../services/PageLoader";
import ChatModal from "../../Modals/ChatModal/ChatModal"
const Conversations = () => {
	const [conversations, setConversations] = useState([]);
    const [show,setShow] = useState(false)
    const [showLoader,setShowLoader] = useState(false)
    const [chatRoomId,setChatRoomId] = useState('')
    const handleShow = (id) => {
        if(id) setChatRoomId(id)
        setShow(!show)
		console.log(id)
    }
    const getData = async() => {
		setShowLoader(true)
        try{
            let resp = await getAllConversation()
            setConversations(resp.data);
        }catch(error){
            console.log(error)
        }
		setShowLoader(false)
    }
    useEffect(() => {
        getData();
      }, []);

	return (
		<div className="card">
			<div>
				{/* begin::Body */}
				<div className='card-body h-100'>
					{conversations.length === 0 ? (
						<p>No conversations available</p>
						) : (
						<div>
							{
							conversations?.map((conv, index) => (
								<div onClick={()=>handleShow(conv.chatRoomId)} key={index} className="d-flex flex-column p-2" style={{border : '1px solid #ddd',cursor : 'pointer'}}>
									<span className="sub_text text_color_1">{conv?.userDetails?.personalInfo?.fullName} to {conv?.hostDetails?.personalInfo?.fullName} ({conv?.propertyDetails?.location_id})</span>
									<span className="content text_color_1">({new Date(conv.createdAt).toLocaleString()})</span>
								</div>
							))
							}
						</div>
					)}
				</div>
				{/* begin::Body */}
			</div>
			{showLoader && <PageLoader />}
			{show && <ChatModal show={show} onHide={handleShow} chatRoomId={chatRoomId}/>}
		</div>
	);
};

export default Conversations;
