import { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import { getConversation } from "../../services/api";
import "./ChatModal.css";
import { Modal } from "react-bootstrap";

const socket = io(process.env.REACT_APP_SPOTLET_SERVICES, {
  transports: ["websocket"], // Forces WebSocket transport
});

const ChatModal = (props) => {
  const { chatRoomId } = props;
  const [userId, hostId, propertyId] = chatRoomId.split("_");
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState({
    chatRoomId: "",
    messages: [],
  });
  let chatName = `${messages?.userDetails?.personalInfo?.fullName} to ${messages?.hostDetails?.personalInfo?.fullName} (${messages?.propertyDetails?.location_id})`;
  const messagesEndRef = useRef(null);

  const getData = async () => {
    try {
      const resp = await getConversation(chatRoomId);
      if (resp?.data) setMessages(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket.emit("joinRoom", { chatRoomId });
    socket.on("message", (message) => {
      setMessages(message);
    });
    getData();
    return () => {
      socket.emit("leaveRoom", { chatRoomId });
    };
  }, []);

  const sendMessage = () => {
    if (newMessage.trim()) {
      const person = "spotlet";
      socket.emit("chatMessage", {
        chatRoomId: messages?.chatRoomId || chatRoomId,
        sender: person,
        message: newMessage,
        userId: messages?.userId || userId,
        hostId: messages?.hostId || hostId,
        propertyId: messages?.propertyId || propertyId,
      });
      setMessages({
        ...messages,
        messages: [
          ...messages.messages,
          { sender: person, message: newMessage, timestamp: new Date() },
        ],
      });
      setNewMessage("");
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Modal 
    show={props?.show} 
    onHide={props?.onHide} 
    size={"md"}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{chatName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sp_chat_main">
          {messages?.messages?.map((msg, index) => (
            <div
              className={
                msg.sender === "user" ? "sp_chat_sub_user" : "sp_chat_sub_host"
              }
            >
              <div
                key={index}
                className={
                  msg.sender === "user"
                    ? "sp_chat_main_sub_user"
                    : "sp_chat_main_sub_host"
                }
              >
                <span className="content">{msg.message}</span>
              </div>
              <span style={{ fontSize: "12px" }}>
                {new Date(msg.timestamp).toLocaleString()}
              </span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center w-100 mt-2">
          <input
            value={newMessage || ""}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            type="text"
            className="textFiled w-100"
          />
          <div className="sp_chat_button_container">
            <button className="btn btn-primary" onClick={sendMessage}>
              Send
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ChatModal;
